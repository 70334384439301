<template>
  <el-container class="my-simple-layout">
    <el-header class="header">
      <my-simple-header />
    </el-header>
    <el-main>
      <slot></slot>
    </el-main>
    <el-footer class="footer center-flex">
      <my-beian />
    </el-footer>
  </el-container>
</template>

<script>
import commonComputeds from '@/utils/commonComputeds'

export default {
  name: "my-simple-layout",
  computed: {
    ...commonComputeds,
  },
  components: {
    "my-beian": () => import("@/components/BeiAn"),
    'my-simple-header': () => import('@/components/SimpleHeader')
  },
};
</script>

<style lang="less">
.my-simple-layout {
  height: 100%;

  .header {
    border-bottom: @hair-border-ccc;
    padding: 0px 15%;
    line-height: 60px;

    .title {
      display: flex;
      align-items: center;
      cursor: pointer;
      
      img {
        margin-right: 1rem;
      }

      h3 {
        margin: 0px;
      }
    }
  }

  .footer {
    border-top: @hair-border-ccc;
  }
}
</style>